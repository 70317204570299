@import "styles/variables";

.page-title {
  font-family: "GT Walsheim-bold";
  font-style: normal;
  font-weight: bold;
  font-size: 18em / $scale;
  line-height: 36px;
  letter-spacing: -0.02em;
}
