@import "styles/variables";

#dispatch-page {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }

  .span-container {
    display: flex;

    .date {
      border-right: 1px solid #737a91;
      padding-right: 10px;
      width: fit-content;
    }
    .time {
      padding-right: 10px;
      width: fit-content;
    }
  }

  .middle-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    .icon-image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
    }
  }

  .stats-icon-orange {
    background: $orange;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-black {
    background: $icon-black;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-valid {
    background: $valid;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    cursor: default;
  }
  .unpaid {
    background-color: $orange;
    color: rgba(245, 150, 37, 1);
    border-color: $orange;
    cursor: pointer;
  }
  .button-container {
    display: inline-flex;
    // justify-content: center;
  }
}
