#createLoan-page {
    .error {
        text-align: left;
        font-size: 13px;
        color: #d34242 !important;
    }

    .xtraClass {
        background-color: transparent !important;
        color: #737a91 !important;
        border-color: transparent !important;
    }
}
