@import "styles/variables";

#loan-page {
  h4 {
    font-family: "GT Walsheim-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: $ash;
  }
  .xtraClass {
    padding: 5px 15px;
  }
  .search-field {
    display: flex;
    @media (max-width: 576px) {
      display: block;
    }
  }
}
