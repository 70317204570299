@import "styles/variables";

#collection-page {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .span-container {
    display: flex;
    // justify-content: center;
    .date {
      border-right: 1px solid #737a91;
      padding-right: 10px;
      width: fit-content;
    }
    .time {
      padding-right: 10px;
      width: fit-content;
    }

    .edit {
      border-right: 1px solid #737a91;
      padding-right: 10px;
      width: fit-content;
      color: #737a91;
      cursor: pointer;
    }
    .delete {
      padding-right: 10px;
      width: fit-content;
      color: #eb5757;
      cursor: pointer;
    }
  }

  .search-field {
    display: flex;
    @media (max-width: 576px) {
      display: block;
    }
  }

  .stats-icon {
    background: $invalid;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: $invalid;
    }
  }
  .stats-icon-leaf {
    background: $leaf;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }

  .search {
    background: #ffffff;
    border: 1px solid $line;
    border-radius: 10px;
    font-size: 14em / $scale;
    line-height: 30px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-family: "GT Walsheim-light";
    }
  }
  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
  }
  .button-container {
    display: flex;
    // justify-content: center;
  }
  .middle-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    .icon-image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
    }
  }
}
