.date-picker {
    position: relative;
    .selected-range {
        background: #ffffff;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        border-radius: 10px;
        // max-width: 16rem;
        padding: 0.8rem 1.5rem;

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    .picker {
        position: absolute;
        top: 48px;
        left: 0;
        left: 0;
        box-shadow: 0 1px 12px rgba(0, 0, 0, 0.08);
        display: none;
        z-index: 10000;

        &.active {
            display: block;
        }
    }
}
