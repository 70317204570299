@import "styles/variables";

#other-info-page {
  h4 {
    font-family: "GT Walsheim-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #737a91;
  }
  .input-width {
    max-width: 20rem;
  }
}
