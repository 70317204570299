@import "styles/variables";

.error-msg {
  padding: 0.6rem 1rem 0.6rem 0.9rem;
  background: rgba(245, 69, 65, 0.1);
  border: 1px solid $error;
  box-sizing: border-box;
  border-radius: 10px;
  p {
    font-family: "GT Walsheim-regular" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 23px !important;
    color: $error !important;
  }
}
.success-msg {
  padding: 0.6rem 1rem 0.6rem 0.9rem;
  // background: rgba(245, 69, 65, 0.1);
  border: 1px solid $green;
  box-sizing: border-box;
  border-radius: 10px;
  p {
    font-family: "GT Walsheim-regular" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 23px !important;
    color: $green !important;
  }
}
