@import "styles/variables";
.edit-household-page {
  .page-title {
    font-family: "GT Walsheim-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #737a91;
  }

  .profile {
    &-image {
      border-radius: 100%;
      background: #ffffff;
      border: 5px solid #e8e8e8;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

      .farmer_image {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    &-text {
      h4 {
        font-family: "GT Walsheim-medium";
        font-weight: 500;
        font-size: 20em / $scale;
        line-height: 30px;
        color: #000000;

        @media screen and (max-width: 769px) {
          font-size: 18em / $scale;
        }
      }

      p {
        line-height: 30px;
        @media screen and (max-width: 769px) {
          line-height: 20px;
        }
      }
    }
  }

  .profile-form {
    margin-top: 10px;
    justify-content: space-between;

    @media screen and (max-width: 769px) {
      display: block;
    }

    .form-details {
      @media screen and (max-width: 769px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .mt-top {
    margin-top: 30px;
  }
  .mt-top-2 {
    margin-top: 40px;
  }
}
