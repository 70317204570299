#farmer-page {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }

  .menus {
    @media screen and (max-width: 769px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .farmers-tab-menus {
    @media screen and (max-width: 769px) {
      display: flex;
      justify-content: space-around;
    }
  }
}
