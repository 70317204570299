.app-wrapper {
  background-color: $white;
  padding: 20px 0px 0px 20px;
  width: 100%;

  @media screen and (max-width: 969px) {
    padding: 0;
  }

  .main-body {
    display: flex;
    width: 100%;

    .right-col {
      background: #fdfdfd;
      border: 1px solid $line;
      box-sizing: border-box;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
      border-radius: 10px 0px 0px 0px;
      width: 100%;
      padding: 20px;
      height: auto;
      min-height: 100vh;

      @media screen and (max-width: 969px) {
        border-radius: 0px 0px 0px 0px;
        box-shadow: none;
      }
    }
  }
}
