.role-modal {
    .xtraClass {
        background-color: transparent !important;
        color: #737a91 !important;
        border-color: transparent !important;
    }
}

.trans{
    transition: ease-in-out 500ms;
}
