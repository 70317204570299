@import "styles/variables";

#stats-card {
  .card {
    background: #ffffff;
    border: 1px solid $line;
    border-radius: 0px 10px;

    .stats {
      white-space: nowrap;
      p {
        &:first-child {
          font-family: "GT Walsheim-medium";
          font-weight: bold;
          font-size: 26em / $scale;
          line-height: 36px;
          color: $dark-ash;
          margin-bottom: 10px;
        }

        &:last-child {
          font-weight: normal;
          font-size: 16em / $scale;
          margin-top: 5px;
          color: $ash;
        }
      }
    }
  }
}
