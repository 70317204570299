@import "styles/variables";

#supply-chain-page {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }

  .stats-icon {
    background: rgba(187, 107, 217, 0.1);
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-cash {
    background: $cash;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-orange {
    background: $orange;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-leaf {
    background: $leaf;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }

  .search {
    background: #ffffff;
    border: 1px solid $line;
    border-radius: 10px;
    font-size: 14em / $scale;
    line-height: 30px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-family: "GT Walsheim-light";
    }
  }
  .button-position {
    // width: 55%;
    // display: flex;
    // justify-content: center;
    // padding: 7px 0px;
    width: auto;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
  }
  .button-container {
    display: flex;
    // justify-content: center;
  }
  .box-container {
    background: #ffffff;
    border: 1px solid $line;
    box-sizing: border-box;
    border-radius: 0px 10px;
    padding: 1rem 3rem;
    p {
      font-family: "GT Walsheim-light";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #9ea0a5;
    }
    h3 {
      font-family: "GT Walsheim-light";
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: 0.116667px;
      color: #4e574f;
    }
  }
  .date {
    border-right: 1px solid #737a91;
    padding-right: 10px;
    width: fit-content;
  }
  .time {
    padding-right: 10px;
    width: fit-content;
  }
  .span-container {
    display: flex;
    // justify-content: center;
  }
  .select-boxs {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 10px;
    max-width: 16rem;
    padding: 0.7rem 1.5rem;
    display: flex;
    justify-content: space-between;
    p {
      font-family: "GT Walsheim-regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $ash;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding-bottom: 20px;
    }
  }
  .report {
    @media (max-width: 767px) {
      display: block !important;
    }
    .select-boxs {
      background-color: rgba(196, 196, 196, 0.1);
      // max-width: 16rem;
      width: 14rem;
      // height: 75%;
      @media (max-width: 767px) {
        // width: 100%;
        width: 100%;
        height: 3.3rem;
        align-items: baseline;
      }
    }
    img {
      width: 22px;
      align-self: center;
      height: 18px;
    }
  }

  .arrow-div {
    max-width: 16rem;
    @media screen and (max-width: 769px) {
      max-width: 100%;
      padding-left: 15px !important;
    }
  }
  .search-field {
    display: flex;
    @media (max-width: 576px) {
      display: block;
    }
  }
  .chart {
    @media screen and (min-width: 769px) {
      border: 1px solid #e8e8e8;
      padding: 1.7rem 0rem 1rem 1rem;
      margin-bottom: 1.5rem;
    }
  }
}
