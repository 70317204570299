@import "styles/variables";

#transaction-page {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }

  .all_tag {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    font-family: "GT Walsheim-medium";
  }

  .paid_tag {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: $green;
    font-family: "GT Walsheim-medium";
  }
  .pro_tag {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #53b7d3;
    font-family: "GT Walsheim-medium";
  }

  .failed_tag {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: $error;
    font-family: "GT Walsheim-medium";
  }

  .unpaid_tag {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: $yellowish;
    font-family: "GT Walsheim-medium";
  }

  .select-boxs {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 10px;
    max-width: 16rem;
    padding: 0.7rem 1.5rem;
    display: flex;
    justify-content: space-between;
    p {
      font-family: "GT Walsheim-regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $ash;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding-bottom: 20px;
    }
  }
  .report {
    @media (max-width: 767px) {
      display: block !important;
    }
    .select-boxs {
      background-color: rgba(196, 196, 196, 0.1);
      // max-width: 16rem;
      width: 14rem;
      // height: 75%;
      @media (max-width: 767px) {
        // width: 100%;
        width: 100%;
        height: 3.3rem;
        align-items: baseline;
      }
    }
    img {
      width: 22px;
      align-self: center;
      height: 18px;
    }
  }

  .page-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18em / $scale;
    line-height: 36px;
    letter-spacing: -0.02em;
  }
  .search-field {
    display: flex;
    @media (max-width: 576px) {
      display: block;
    }
  }
  .stats-icon-cash {
    background: $cash;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-leaf {
    background: $leaf;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }
  .stats-icon-valid {
    background: $valid;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }
  .stats-icon-invalid {
    background: $invalid;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }
  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
  }
  .button-container {
    display: flex;
    // justify-content: center;
  }
  .btn-style {
    min-width: 8rem;
    @media (max-width: 767px) {
      width: 100% !important;
      margin-bottom: 1.5rem !important;
    }
  }

  .Owing {
    background-color: $orange;
    color: rgba(245, 150, 37, 1);
    border-color: $orange;
  }

  .Failed {
    background-color: $error;
    color: #fff;
    border-color: $error;
  }

  .Processing {
    background-color: #53b7d3;
    color: #fff;
    border-color: #53b7d3;
  }

  @media (max-width: 576px) {
    .r-contain {
      padding: 0%;
    }
  }
  .arrow-div {
    max-width: 16rem;
    @media screen and (max-width: 769px) {
      max-width: 100%;
      // padding-left: 15px !important;
    }
  }
  .arrow-div2 {
    min-width: 7.3rem;
    @media screen and (max-width: 769px) {
      max-width: 100%;
      padding-left: 15px !important;
    }
  }
  .gap {
    @media screen and (max-width: 769px) {
      row-gap: 1rem;
    }
  }
  .date {
    border-right: 1px solid #737a91;
    padding-right: 10px;
    width: fit-content;
  }
  .time {
    padding-right: 10px;
    width: fit-content;
  }
  .span-container {
    display: flex;
    // justify-content: center;
  }
  .box-scroll {
    max-height: 17rem;
    overflow-y: scroll;
  }
}
