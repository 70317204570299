@import "variables.scss";
*,
*::before,
*::after {
  box-sizing: inherit;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

body {
  font-family: "GT Walsheim-regular";
  font-size: 16px;
  line-height: 24px;
  color: $ash;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box !important;
}

html {
  overscroll-behavior: contain none !important;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

ul {
  padding-left: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
