#dispatch-modal {
    .textarea {
        background: #ffffff;
        border: 1px solid #dfe2e6;
        box-sizing: border-box;
        border-radius: 10px;
        resize: none;
        padding: 15px 20px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: #737a91;
            opacity: 0.5;
        }
    }
}
