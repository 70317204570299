button {
    background-color: transparent;
    border: none;
    outline: none;
}
.on {
    color: gold;
}
.off {
    color: #ccc;
}
.star {
    font-size: 1.2rem;
}
.star-rating {
    white-space: nowrap;
    
}
