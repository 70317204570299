@import "styles/variables";

.tab-menu {
  margin-top: 20px;

  @media screen and (max-width: 769px) {
    margin-top: 10px;
  }
  position: relative;
  &::after {
    display: inline-block;
    content: "" "";
    width: 100%;
    height: 0.25em;
    position: absolute;
    bottom: 0.2em;
    left: 0;
    border-bottom: 1px solid $line;
  }

  .item {
    display: inline-block;
    width: max-content;
    // padding: 0 5px;
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 0.005em;
    margin: 0.3em 3em 1.14em 0;
    color: $dark-ash;
    position: relative;
    line-height: 30px;
    cursor: pointer;

    @media screen and (max-width: 769px) {
      margin-right: 0.7rem;
    }

    &::after {
      display: inline-block;
      content: "";
      width: 100%;
      height: 0.25em;
      position: absolute;
      bottom: -0.9em;
      left: 0;
    }

    &--active {
      & > span {
        color: $green;
        font-family: "GT Walsheim-medium";

        &::after {
          border-bottom: 3px solid $green;
          // background-color: #67bbfb;
        }

        & > span {
          background-color: #92929d;
          // color: #3065cd;
          color: $green;
          font-family: "GT Walsheim-medium";
        }
      }
    }
  }
}
