@import "styles/variables";

// #select-box {
.combo-box {
  padding: 0px !important;
}
.no-focus {
  &:focus-within fieldset {
    border: 1px solid #e8e8e8 !important;
    transition: none !important;
  }
}

.select {
  &:focus {
    outline: none;
  }
  &:focus-within fieldset {
    border: 1px solid $green;
    transition: ease-in 500ms;
  }

  .disabled-input legend {
    color: $ash;
  }
  .disabled-input {
    // border: 1px solid #d5d5d5;
  }
  .error {
    border: 1px solid #d34242;
    background-color: transparent !important;
  }
  .label-error {
    color: #d34242;
  }
  &:focus-within legend {
    color: $ash;
    transition: ease-in-out 500ms;
  }
  fieldset {
    min-width: 0;
    padding: 3px 0;
    margin: 0;
    border: 1px solid #dfe2e6;
    border-radius: 8px;
    outline: none;
  }
  legend {
    max-width: fit-content;
    line-height: 20px;
    color: #e5e5e5;
    white-space: normal;
    text-align: left;
    font-size: 12px;
    margin-left: 18px;
    margin-bottom: -10px;
    font-weight: 400;
  }
  input {
    width: 100%;
    height: auto;
    outline: none;
    border: 0;
    background: transparent;

    &::placeholder {
      color: $ash;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input-error {
    text-align: left;
    font-size: 12px;
    color: #d34242 !important;
  }
}
