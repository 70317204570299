.generate-button {
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 10px;
    max-width: 12.5rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
    p {
        font-family: "GT Walsheim-regular";
        font-size: 16px;
        line-height: 30px;
        color: #505566;
    }
    @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        justify-content: center;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
    }
}
