@import "styles/variables";

#navbar {
  .eyowo_balance {
    color: $green;
    font-size: 1em;
    font-weight: 500;
    font-family: "GT Walsheim-medium";
    margin-top: 0.5rem;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  padding: 0px 30px 0px 10px;

  .nav-dropdown {
    @media screen and (max-width: 768px) {
      left: -90px;
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
  }

  .route_name {
    text-transform: capitalize;
    display: inline-block;
  }

  .logout_tag {
    color: #eb5757;
  }

  @media screen and (max-width: 969px) {
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .hamburger {
    display: none;
    @media screen and (max-width: 969px) {
      display: block;
    }
  }

  .navbar-text {
    font-family: "GT Walsheim-medium";
    color: $dark-ash;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6em;
    line-height: 36px;
    padding: 0px;
    transform: translateX(90px);
    // text-align: right;

    @media screen and (max-width: 969px) {
      font-weight: 600;
      font-family: "GT Walsheim-regular";
      font-size: 1.5em;
      line-height: 27px;
      transform: translateX(0px);
    }

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .profile-tab {
    // position: relative;
    .icon {
      margin-right: 10px;
      width: 1.3em;
      &-avatar {
        border: 1px;
        background: #c6c6c6;
        border-radius: 6px;
        margin-right: 10px;
        padding: 0.2rem;
      }
    }

    .user-profile {
      @media screen and (max-width: 768px) {
        margin-top: 1.5rem;
      }
    }

    p {
      font-size: 1em;
      font-weight: 500;
      font-family: "GT Walsheim-medium";
    }
  }
  .arrow-img {
    width: 1.5rem;
    height: 1.7rem;
    align-self: center;
  }
}
