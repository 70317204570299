#add-produce-modal {
    .rating-text {
        line-height: 30px;
    }

    // .star-class {
    //     font-size: 3rem;
    //     padding-right: 25px;
    // }

    .star-class {
        font-size: 2rem;
        padding-right: 2rem;
        @media screen and (max-width: 576px) {
            padding-right: 1rem;
        }
    }

    .error {
        text-align: left;
        font-size: 13px;
        color: #d34242 !important;
    }
}
