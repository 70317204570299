.bodyfix {
  overflow: hidden;
}

.btn.btn-sm {
  height: 48em / $scale;
  font-size: 14em / $scale;
  padding: 0 20em / $scale;
  border-radius: 10px;
  font-size: 1rem;
}

.btn.btn-md {
  height: 45em / $scale;
  font-size: 14em / $scale;
  padding: 0 45em / $scale;
  border-radius: 10px;
  width: -webkit-fill-available;
}

.btn.btn-lg {
  height: 55em / $scale;
  font-size: 16em / $scale;
  padding: 0 55em / $scale;
  border-radius: 10px;
}

.btn {
  &:focus {
    box-shadow: none !important;
  }
}

.btn.btn-primary {
  background-color: $green;
  border-radius: 10px;
  border-color: $green;
  padding: 0.7375rem 0;

  &:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  &:active {
    background-color: $green !important;
    border: none !important;
  }
}

.btn.btn-secondary {
  background-color: $light-green;
  border-radius: 10px;
  border-color: $light-green;
  padding: 0.7375rem 0;
  color: $green;
  // font-size: 1.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.mobile-none {
  @media screen and (max-width: 969px) {
    display: none;
  }
}

.modal-length {
  overflow-y: scroll;
}

.text-center {
  text-align: center;
}

// Toast Class
.toastr {
  .toastr.animated.rrt-success {
    background-color: $green !important;
  }
}
