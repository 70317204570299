@import "styles/variables";

#pagination-view {
  .page-numbers {
    a {
      font-weight: 700;
      line-height: 30px;
    }

    .page-color {
      color: $green;
    }
  }
}
