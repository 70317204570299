@import "styles/variables";

.modal-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100vh;
  background-color: "";
  z-index: 9000000;
  display: flex;
  align-items: center;
  justify-content: center;

  .backDrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    backdrop-filter: blur(3px);
  }

  .contentwrapper {
    background-color: #fff;
    z-index: 3;
    background: #ffffff;
    border: 0.6px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 7px rgba(61, 181, 74, 0.09);
    width: 80%;
    max-width: 401px;
    height: 100%;
    position: relative;

    & .modal_header {
      display: flex;
      justify-content: space-between;
      padding: 25px 30px 25px 30px;
      background: #ffffff;
      box-shadow: 0px 1px 6px rgba(188, 188, 188, 0.4);

      h4 {
        font-family: "GT Walsheim-medium";
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 30px;
        color: $black;
      }
    }

    & .modal_content {
      padding: 25px 30px 25px 30px;

      max-height: 40rem;
      height: 100%;
    }
  }
}
