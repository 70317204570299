@import "styles/variables";

#singlehousehold-page {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }

  .search-field {
    display: flex;
    @media (max-width: 576px) {
      display: block;
    }
  }

  .stats-icon {
    background: rgba(187, 107, 217, 0.1);
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-cash {
    background: $cash;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-orange {
    background: $orange;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-leaf {
    background: $leaf;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }

  .search {
    background: #ffffff;
    border: 1px solid $line;
    border-radius: 10px;
    font-size: 14em / $scale;
    line-height: 30px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-family: "GT Walsheim-light";
    }
  }
  .button-position {
    // width: 55%;
    // display: flex;
    // justify-content: center;
    // padding: 7px 0px;
    width: auto;
    display: flex;
    justify-content: center;
    // padding: 8px 16px;
    padding: 6px 20px;
  }
  .button-container {
    display: flex;
    // justify-content: center;
  }
  .box-container {
    background: #ffffff;
    border: 1px solid $line;
    box-sizing: border-box;
    border-radius: 0px 10px;
    padding: 2rem 3rem;

    .farmer-stats {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 769px) {
        display: block;
      }
      p {
        font-family: "GT Walsheim-light";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #9ea0a5;
        @media screen and (max-width: 769px) {
          margin-bottom: 5px;
        }
      }
    }

    h3 {
      font-family: "GT Walsheim-light";
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: 0.116667px;
      color: #4e574f;
    }
  }
  .pay {
    border-right: 1px solid #737a91;
    padding-right: 10px;
    color: $green;
    width: fit-content;
  }
  .edit {
    // border-right: solid 1px #737a91;
    padding-right: 10px;
    color: #737a91;
    width: fit-content;
  }
  .deactivate {
    color: #eb5757;
    // border-right: solid 1px #737a91;
    padding-right: 10px;
    width: fit-content;
  }
  .span-container {
    display: flex;
    // justify-content: center;
  }
}
