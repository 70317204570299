@import "styles/variables";

.select-dropdown {
  position: relative;
  width: 100%;

  .scroll {
    overflow-y: scroll;
    max-height: 17rem;
  }

  .select-role {
    color: $light-black !important;
  }

  & .select-box {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0.8rem 1.5rem;
    display: flex;
    justify-content: space-between;

    input {
      width: 100%;
      height: auto;
      outline: none;
      border: 0;
      background: transparent;
    }
    p {
      font-family: "GT Walsheim-regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $ash;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding-bottom: 20px;
    }
  }

  & .select-options {
    background: #ffffff;
    border: 0.5px solid $line;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: auto;
    z-index: 2;
    position: absolute;
    width: 100%;

    & .options-dropdown {
      margin-top: 15px;
      margin-bottom: 15px;

      &.label {
        overflow-y: scroll;
        height: 290px;
      }

      li {
        padding: 5px 25px 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;

        &:hover {
          background-color: $light-green;
        }
      }
    }
  }
}
