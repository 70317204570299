#pay-farmer-page {
    .star-class {
        font-size: 2rem;
        padding-right: 2rem;
        @media screen and (max-width: 576px) {
            padding-right: 1rem;
        }
    }
    .star-div {
        display: flex;
        justify-content: center;
    }
    .box-class {
        width: 100%
        // border: solid;
    }
    .xtra-right {
        width: 90% !important;
        padding: 0.6rem 1.8rem;
        margin-left: auto;
        @media screen and (max-width: 576px) {
            padding: 0.7375rem 0;
        }
    }

    .xtra-left {
        // width: 90% !important;
        padding: 0.5rem 1.8rem;
        @media screen and (max-width: 576px) {
            padding: 0.7375rem 0;
        }
    }
}
