@import "styles/variables";

#inventory-page {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }

  .stats-icon-cash {
    background: $cash;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    // padding: 8px 16px;
    padding: 6px 20px;
  }
  .button-container {
    display: flex;
    // justify-content: center;
  }
}
