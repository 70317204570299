.role-modal {
    .role-p {
        text-align: center;
        position: relative;
        bottom: 1rem;
        cursor: pointer;
    }
}

.role-modal-top {
    top: -120px;
}
.error {
    text-align: left;
    font-size: 13px;
    color: #d34242 !important;
}
