@import "styles/variables";

#transaction-data-page {
  h4 {
    font-family: "GT Walsheim-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #737a91;
  }
  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
  }
  .button-container {
    display: flex;
    // justify-content: center;
  }

  .unpaid {
    background-color: $orange;
    color: rgba(245, 150, 37, 1);
    border-color: $orange;
  }
}
